import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import "./Catslide.css"; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const Catslide5 = () => {
  const [cards, setCards] = useState([]);

  const navigate = useNavigate();

  const handleDivClick = (store_id) => {
    localStorage.setItem("store_id", store_id);
    navigate("/Foods", { replace: true });
  };

  
   const api_url = localStorage.getItem('api_url');
    const data=[];
     const prescription ='no';
     const search='';
  

  useEffect(() => {
    const fetchCards = async () => {
      try {
        // const response = await axios.get("/cards"); 
        // setCards(response.data); 
        axios({
            method: 'get',
            url:api_url+'/recommend_side',               
            
          }).then(res => {
            const data = res.data;
           // console.log(data);
           setCards(data.recommended_addons);
          });



      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCards();
  }, []); // Empty dependency array to run the effect only once on mount

  const [startIndex, setStartIndex] = useState(0);

  const onNextClick1 = () => {
    const newIndex = startIndex + 5;
    if (newIndex < cards.length) {
      setStartIndex(newIndex);
    }
  };

  const onPrevClick1 = () => {
    const newIndex = startIndex - 5;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  return (
    <div className="">
      <div className="car_d row">
      {cards.map((card,index) => (
    <div
      key={card.store_id}
      className="col-md-2"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "10%",
        height: "300px",
        margin: "10px",
        padding: "40px",
      }}
      onClick={() => handleDivClick(card.store_id)}
    >
      <img className="med_img" style={{height:"50%"}} src={card.product_image} alt={card.product_name} />
      <hr />
      <div className="medicine-details">
        <p>{card.product_name}</p>
        <p>{card.addons}</p>
      </div>
    </div>
  ))}

      </div>
      {/* <br></br> */}
      {/* <div className="controls">
        <button className="bu_n" onClick={onPrevClick1} disabled={startIndex === 0}>
          Prev
        </button>
        <button className="bu_n" onClick={onNextClick1} disabled={startIndex + 5 >= cards.length}>
          Next
        </button>
      </div> */}
      
    </div>
  );
};

export default Catslide5;
